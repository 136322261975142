import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Box, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

interface RatingSectionProps {
    title: string;
    score: number;
    details: any;
}

const RatingSection: React.FC<RatingSectionProps> = ({ title, score, details }) => {
    const [expanded, setExpanded] = useState<boolean | string>(false); // Controls the individual expansion of sub-accordions
    const [expandAll, setExpandAll] = useState(false); // Controls expanding all sub-accordions
    const [mainExpanded, setMainExpanded] = useState(false); // Controls the main accordion

    const handleExpandAll = (event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent the main accordion from toggling when clicking the "Expand All" button
        setExpandAll(!expandAll);
    };

    const handleMainAccordionChange = () => {
        setMainExpanded(!mainExpanded);
        if (!mainExpanded) {
            setExpandAll(false); // Reset expandAll when the main accordion is closed
        }
    };

    return (
        <Accordion
            expanded={mainExpanded}
            onChange={handleMainAccordionChange}
            sx={{
                backgroundColor: '#333',
                color: 'white',
                mb: 2,
            }}
        >
            <AccordionSummary
                expandIcon={
                    <Tooltip title={mainExpanded ? 'Collapse' : 'Expand'} arrow>
                        <ExpandMoreIcon sx={{ color: 'white' }} />
                    </Tooltip>
                }
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white' }}>
                        {title}: {score} / 20
                    </Typography>
                    {mainExpanded && (
                        <Tooltip title={expandAll ? 'Collapse All' : 'Expand All'} arrow>
                            <Button
                                onClick={handleExpandAll}
                                sx={{ color: 'white', textTransform: 'none', display: 'flex', alignItems: 'center' }}
                                startIcon={expandAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                            >
                            </Button>
                        </Tooltip>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {details.sharpness_and_detail && (
                    <Accordion
                        expanded={expandAll || expanded === 'sharpness'}
                        onChange={() => setExpanded(expanded === 'sharpness' ? false : 'sharpness')}
                        sx={{ backgroundColor: '#444', color: 'white' }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Tooltip title={expanded === 'sharpness' ? 'Collapse' : 'Expand'} arrow>
                                    <ExpandMoreIcon sx={{ color: 'white' }} />
                                </Tooltip>
                            }
                        >
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Sharpness and Detail
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {details.sharpness_and_detail}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                {details.color_handling && (
                    <Accordion
                        expanded={expandAll || expanded === 'color_handling'}
                        onChange={() => setExpanded(expanded === 'color_handling' ? false : 'color_handling')}
                        sx={{ backgroundColor: '#444', color: 'white' }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Tooltip title={expanded === 'color_handling' ? 'Collapse' : 'Expand'} arrow>
                                    <ExpandMoreIcon sx={{ color: 'white' }} />
                                </Tooltip>
                            }
                        >
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Color Handling
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {details.color_handling}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                {details.composition && (
                    <Accordion
                        expanded={expandAll || expanded === 'composition'}
                        onChange={() => setExpanded(expanded === 'composition' ? false : 'composition')}
                        sx={{ backgroundColor: '#444', color: 'white' }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Tooltip title={expanded === 'composition' ? 'Collapse' : 'Expand'} arrow>
                                    <ExpandMoreIcon sx={{ color: 'white' }} />
                                </Tooltip>
                            }
                        >
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Composition
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {details.composition}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                {details.innovative_concept && (
                    <Accordion
                        expanded={expandAll || expanded === 'innovative_concept'}
                        onChange={() => setExpanded(expanded === 'innovative_concept' ? false : 'innovative_concept')}
                        sx={{ backgroundColor: '#444', color: 'white' }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Tooltip title={expanded === 'innovative_concept' ? 'Collapse' : 'Expand'} arrow>
                                    <ExpandMoreIcon sx={{ color: 'white' }} />
                                </Tooltip>
                            }
                        >
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Innovative Concept
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {details.innovative_concept}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                {details.avoidance_of_cliches && (
                    <Accordion
                        expanded={expandAll || expanded === 'avoidance_of_cliches'}
                        onChange={() => setExpanded(expanded === 'avoidance_of_cliches' ? false : 'avoidance_of_cliches')}
                        sx={{ backgroundColor: '#444', color: 'white' }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Tooltip title={expanded === 'avoidance_of_cliches' ? 'Collapse' : 'Expand'} arrow>
                                    <ExpandMoreIcon sx={{ color: 'white' }} />
                                </Tooltip>
                            }
                        >
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Avoidance of Clichés
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {details.avoidance_of_cliches}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                {details.adherence_to_style && (
                    <Accordion
                        expanded={expandAll || expanded === 'adherence_to_style'}
                        onChange={() => setExpanded(expanded === 'adherence_to_style' ? false : 'adherence_to_style')}
                        sx={{ backgroundColor: '#444', color: 'white' }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Tooltip title={expanded === 'adherence_to_style' ? 'Collapse' : 'Expand'} arrow>
                                    <ExpandMoreIcon sx={{ color: 'white' }} />
                                </Tooltip>
                            }
                        >
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Adherence to Style
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {details.adherence_to_style}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
                {details.coherence && (
                    <Accordion
                        expanded={expandAll || expanded === 'coherence'}
                        onChange={() => setExpanded(expanded === 'coherence' ? false : 'coherence')}
                        sx={{ backgroundColor: '#444', color: 'white' }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Tooltip title={expanded === 'coherence' ? 'Collapse' : 'Expand'} arrow>
                                    <ExpandMoreIcon sx={{ color: 'white' }} />
                                </Tooltip>
                            }
                        >
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                                Coherence
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {details.coherence}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default RatingSection;