import { Box, Container, Link, Typography } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import { colors } from './colors';

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 1,
                px: 1,
                mt: 0,
                backgroundColor: colors.backgroundColors.pageBackground,
            }}
        >
            <Container maxWidth="lg">
                {/* Copyright Section */}
                <Box sx={{ mt: 0 }}>
                    <Typography variant="body2" sx={{ color: 'white' }} align="center">
                        {'Copyright © '}
                        <Link color="inherit" href="#">
                            Megamask LTD
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'. All rights reserved.'}
                    </Typography>
                </Box>
            </Container>
        </Box >
    );
}
