import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export const PriceTag = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    backgroundColor: 'lightgreen',
    borderRadius: '4px',
    marginLeft: theme.spacing(1),
}));

export const NeonCard = styled(Card)(({ theme }) => ({
    maxWidth: 345,
    borderRadius: '16px',
    background:  'lightgray',
    boxShadow: '0 0 10px rgba(255, 255, 255, 0.2), 0 0 20px rgba(255, 255, 255, 0.2), 0 0 30px rgba(255, 255, 255, 0.2)',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.4), 0 0 60px rgba(255, 255, 255, 0.4)',
    },
}));

export const CardDescription = styled(Box)({
    maxHeight: '6em',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
});

export const SlowCircularProgress = styled(CircularProgress)({
    animationDuration: '2s',
});

export const ImageLoadingContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
});

export const CardHeaderTitle = styled(Typography)({
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '220px',
    textAlign: 'left',
    background: 'linear-gradient(279deg, #638596 0%, #8e6fb5 50%, #95554e 100%)',
    //font: 'Circularstd',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
});



export const DialogImage = styled('img')({
    width: '100%',
    height: 'auto',
    border: 'none',
    display: 'block',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
});


