import React, { useState } from 'react';
import { TextField, Box, Button, Card, CardContent, CardMedia, Typography, CssBaseline } from '@mui/material';
import Grid from '@mui/material/Grid2';

export default function NFTMintPage() {
    const [orderId, setOrderId] = useState('');
    const [email, setEmail] = useState('');
    const [images, setImages] = useState<string[]>([]);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async () => {
        setSubmitted(true);
        const dummyImages = [
            'https://via.placeholder.com/150',
            'https://via.placeholder.com/150',
            'https://via.placeholder.com/150',
        ];
        setImages(dummyImages);
    };

    return (
        <Box component='main' sx={{ flexGrow: 1, mt: '60px' }}>
            <Grid>
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <CssBaseline />
                    <Typography variant="h4" gutterBottom align="center">
                        Kép Lekérdezés
                    </Typography>
                    <Box>
                        <TextField
                            label="OrderID"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            style={{ marginTop: '20px' }}
                        >
                            Lekérdezés
                        </Button>
                    </Box>
                    {submitted && (
                        <Grid>
                            <Card style={{ marginTop: '20px', padding: '20px' }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Visszakapott Képek
                                    </Typography>

                                    <Grid container spacing={2}>
                                        {/* Bal oldali kép lista */}
                                        <Grid>
                                            {images.map((image, index) => (
                                                <Card key={index} style={{ marginBottom: '10px' }}>
                                                    <CardMedia
                                                        component="img"
                                                        image={image}
                                                        alt={`Image ${index + 1}`}
                                                        height="150"
                                                    />
                                                </Card>
                                            ))}
                                            <Button variant="contained" color="secondary" fullWidth>
                                                Minted az External Walletedre
                                            </Button>
                                        </Grid>

                                        {/* Jobb oldali regisztrációs gomb */}
                                        <Grid>
                                            <Button variant="outlined" color="primary" fullWidth>
                                                Regisztrálj a Weboldalra
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Box>
            </Grid >
        </Box>
    );
}