import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Box, createTheme, styled, ThemeProvider } from '@mui/material';
import { ProvideAuth } from '../hooks/useAuth';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import LoginPage from './Login/LoginPage';
import { Layout } from './Layout/Layout';
import { NotFoundPage } from './Common';
import HomePage from './Home/HomePage';
import RegisterPage from './Register/RegisterPage';
import GalleryPage from './Gallery/GalleryPage';
import MyTeamPage from './MyTeam/MyTeamPage';
import { MetaMaskProvider } from "@metamask/sdk-react";
import NFTMintPage from './NFTMint/NFTMintPage';


const StyledApp = styled(Box)`
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

const theme = createTheme({
  palette: {
    primary: {
      main: '#002E2E',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledApp>
        <ProvideSnackBar>
          <MetaMaskProvider
            sdkOptions={{
              dappMetadata: {
                name: "OpenAIrt React Dapp",
                url: window.location.href,
              }
            }}
          >
            <ProvideAuth>
              <Layout>
                <BrowserRouter>
                  <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/home' element={<HomePage />} />
                    <Route path='/register' element={<RegisterPage />} />
                    <Route path='/login' element={<LoginPage />} />
                    <Route path='/gallery' element={<GalleryPage />} />
                    <Route path='/team' element={<MyTeamPage />} />
                    <Route path='/nft-mint' element={<NFTMintPage />} />
                    <Route path='*' element={<NotFoundPage />} />
                  </Routes>
                </BrowserRouter>
              </Layout>
            </ProvideAuth>
          </MetaMaskProvider>
        </ProvideSnackBar>
      </StyledApp>
    </ThemeProvider >
  );
}

export default App;
