import { useState } from "react";
import { Button, FormControl, FormHelperText, TextField, Typography, Paper, Avatar } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { AuthResponseType } from "../../@types/auth";
import { useAuth } from "../../hooks/useAuth";
import { useProvideSnackBar } from '../../hooks/useSnackBar';
import { useNavigate, useSearchParams } from 'react-router-dom';

const OPENAIRT_API_ENDPOINT = process.env.REACT_APP_OPENAIRT_API_URL || '';

export default function RegisterPage() {
    const { register } = useAuth();
    const [search] = useSearchParams();
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [partnerId, setPartnerId] = useState(search.get('partnerId') || '');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const fetchRegister = async () => {
    }

    return (
        <Grid container spacing={2} justifyContent={'center'} paddingTop={5}>
            <Paper
                sx={{
                    padding: 2,
                    height: 'fit-content',
                    width: '60rem',
                    flexDirection: 'column',
                    display: 'flex',
                    paddingTop: 1,
                    marginTop: 1,
                }}>
                <Grid container justifyContent={'center'}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">Sign up</Typography>
                </Grid>
                <Grid container p={2} spacing={2} justifyContent={'center'}>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                required
                                id="firstName" label="First Name" name="firstName"
                                autoComplete="firstName" autoFocus
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                required
                                id="lastName" label="Last Name" name="lastName"
                                autoComplete="lastName"
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                required
                                id="email" label="Email Address" name="email"
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                id="affiliateID" label="Affiliate ID" name="affiliateID"
                                value={partnerId}
                                onChange={(e) => setPartnerId(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                required
                                name="password" label="Password" type="password"
                                id="password" autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <FormControl margin="normal" fullWidth>
                            <TextField
                                required
                                name="confirmPassword" label="Confirm Password" type="password"
                                id="confirmPassword" autoComplete="current-password"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {password !== confirmPassword && (
                                <FormHelperText error>Password does not match</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => {
                                if (password !== confirmPassword) {
                                    return;
                                }
                                register({
                                    firstName,
                                    lastName,
                                    email,
                                    partnerId,
                                    password
                                });
                            }}
                        >
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
