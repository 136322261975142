import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogContent, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NeonCard, PriceTag, CardDescription, SlowCircularProgress, ImageLoadingContainer, CardHeaderTitle, DialogImage } from './styles';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import Rating from '@mui/material/Rating';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RatingSection from './RatingSection';  // Importáljuk a RatingSection komponenst
import { GalleryItem } from '../../@types/gallery';  // GalleryItem típus

interface GalleryCardProps {
    galleryItem: GalleryItem;  // Típus hozzárendelése a galleryItem-hez
}

export default function GalleryCard({ galleryItem }: GalleryCardProps) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ratingDialogOpen, setRatingDialogOpen] = useState(false); // Állapot a rating felugró ablakhoz
    const [expanded, setExpanded] = useState(false); // Az accordion állapota
    const theme = useTheme();

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleImageLoad = () => setLoading(false);

    // Felugró ablak nyitása és zárása a Ratingre kattintva
    const handleRatingClick = () => setRatingDialogOpen(true);
    const handleRatingClose = () => setRatingDialogOpen(false);

    const starRating = galleryItem.rating.total_score / 20;
    const formattedPrice = new Intl.NumberFormat('en-EN', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(galleryItem.price / 100);

    // Accordion nyitás/zárás állapot kezelése
    const handleAccordionToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <NeonCard>
            <CardHeader
                title={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Tooltip title={galleryItem.title} arrow>
                            <CardHeaderTitle variant="h6">
                                {galleryItem.title}
                            </CardHeaderTitle>
                        </Tooltip>
                        <PriceTag>
                            <EuroSymbolIcon fontSize="small" />
                            <Typography variant="body2" sx={{ fontWeight: 'bold', marginLeft: 0.5 }}>
                                {formattedPrice}
                            </Typography>
                        </PriceTag>
                    </Box>
                }
                subheader={`ID: #${galleryItem.id}`}
            />

            <Box sx={{ position: 'relative', height: '194px' }}>
                {loading && (
                    <ImageLoadingContainer>
                        <SlowCircularProgress />
                    </ImageLoadingContainer>
                )}
                <CardMedia
                    component="img"
                    height="194"
                    image={galleryItem.url}
                    alt={galleryItem.title}
                    onLoad={handleImageLoad}
                    onClick={handleClickOpen}
                    sx={{ cursor: 'pointer', display: loading ? 'none' : 'block' }}
                />
            </Box>

            <CardDescription>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {galleryItem.description}
                </Typography>
            </CardDescription>

            {/* Rating komponens kattintási eseménnyel */}
            <Tooltip title={`Rating: ${galleryItem.rating.total_score}/100`} arrow>
                <Box sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1 }} onClick={handleRatingClick}>
                    <Rating
                        name="read-only"
                        value={starRating}
                        precision={0.1}
                        readOnly // Ezzel megmarad a csillagok megjelenése, de a Box kezelheti a kattintást
                    />
                </Box>
            </Tooltip>

            {/* Kép felugró ablak */}
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent sx={{ position: 'relative', padding: 0 }}>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogImage src={galleryItem.url} alt={galleryItem.title} />
                </DialogContent>
            </Dialog>

            {/* Rating részleteit tartalmazó felugró ablak */}
            <Dialog
                open={ratingDialogOpen}
                onClose={handleRatingClose}
                fullWidth
                maxWidth={expanded ? 'md' : 'sm'} // Dinamikusan változó szélesség
            >
                <DialogContent>
                    <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', mb: 3 }}>
                        Total Score: {galleryItem.rating.total_score} / 100
                    </Typography>

                    <Accordion expanded={expanded} onChange={handleAccordionToggle}
                        sx={{
                            backgroundColor: '#444',
                            color: 'white',
                            mb: 2,
                        }}>
                        <AccordionSummary expandIcon={
                    <Tooltip title={expanded ? 'Collapse' : 'Expand'} arrow>
                        <ExpandMoreIcon sx={{ color: 'white' }} />
                    </Tooltip>
                }>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'white' }}>Rating Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* Technical Execution */}
                            <RatingSection
                                title="Technical Execution"
                                score={galleryItem.rating.technical_exec.score}
                                details={galleryItem.rating.technical_exec.details}
                            />
                            {/* Originality */}
                            <RatingSection
                                title="Originality"
                                score={galleryItem.rating.originality.score}
                                details={galleryItem.rating.originality.details}
                            />
                            {/* Style */}
                            <RatingSection
                                title="Style"
                                score={galleryItem.rating.style.score}
                                details={galleryItem.rating.style.details}
                            />
                            {/* Emotions */}
                            <RatingSection
                                title="Emotions"
                                score={galleryItem.rating.emotions.score}
                                details={galleryItem.rating.emotions.details}
                            />
                            {/* Market */}
                            <RatingSection
                                title="Market"
                                score={galleryItem.rating.market.score}
                                details={galleryItem.rating.market.details}
                            />
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
            </Dialog>
        </NeonCard>
    );
}