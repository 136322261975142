import React from 'react';
import { Box, Typography } from "@mui/material";

const NotFoundPage = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="h1">404 |</Typography>
            <Typography variant="h1" color="primary">Not Found</Typography>
        </Box>
    );
}

export { NotFoundPage }