import React from 'react';
import * as Icons from '@mui/icons-material';

// A `resolveIcon` funkció egy string nevet vár, és visszaadja a megfelelő ikont vagy null-t, ha nem található.
export const resolveIcon = (iconName: string): React.ReactElement | null => {
    // A megfelelő ikon kiválasztása az ikonok objektumból
    const IconComponent = (Icons as any)[iconName];

    if (!IconComponent) {
        console.warn(`Icon '${iconName}' not found in Material UI icons.`);
        return null;
    }

    // Visszaadja az ikont React elemként
    return <IconComponent color = 'red'/>;
};