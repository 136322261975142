export interface Distributor {
    ID: number;
    UserID: string;
    FirstName: string;
    LastName: string;
    Rank: 'Costumer' | 'Affiliate' | 'Leader 1' | 'Leader 2' | 'Leader 3';
    PartnerID: number | null;
    TotalPersonalPoints: number;
    TotalTeamPoints: number;
  }
  
  export const distributorsData: Distributor[] = [
    {
      ID: 1,
      UserID: 'john_doe',
      FirstName: 'John',
      LastName: 'Doe',
      Rank: 'Leader 3',
      PartnerID: null,
      TotalPersonalPoints: 6587,
      TotalTeamPoints: 29822,
    },
    {
      ID: 2,
      UserID: 'jane_smith',
      FirstName: 'Jane',
      LastName: 'Smith',
      Rank: 'Leader 2',
      PartnerID: 1,
      TotalPersonalPoints: 4902,
      TotalTeamPoints: 16792,
    },
    {
      ID: 3,
      UserID: 'alice_johnson',
      FirstName: 'Alice',
      LastName: 'Johnson',
      Rank: 'Leader 1',
      PartnerID: 1,
      TotalPersonalPoints: 4521,
      TotalTeamPoints: 12750,
    },
    {
      ID: 4,
      UserID: 'bob_williams',
      FirstName: 'Bob',
      LastName: 'Williams',
      Rank: 'Affiliate',
      PartnerID: 2,
      TotalPersonalPoints: 7623,
      TotalTeamPoints: 7623,
    },
    {
      ID: 5,
      UserID: 'mary_davis',
      FirstName: 'Mary',
      LastName: 'Davis',
      Rank: 'Affiliate',
      PartnerID: 2,
      TotalPersonalPoints: 4267,
      TotalTeamPoints: 4267,
    },
    {
      ID: 6,
      UserID: 'james_wilson',
      FirstName: 'James',
      LastName: 'Wilson',
      Rank: 'Affiliate',
      PartnerID: 3,
      TotalPersonalPoints: 6330,
      TotalTeamPoints: 6330,
    },
    {
      ID: 7,
      UserID: 'patricia_moore',
      FirstName: 'Patricia',
      LastName: 'Moore',
      Rank: 'Costumer',
      PartnerID: 3,
      TotalPersonalPoints: 6420,
      TotalTeamPoints: 6420,
    },
    {
      ID: 8,
      UserID: 'linda_taylor',
      FirstName: 'Linda',
      LastName: 'Taylor',
      Rank: 'Affiliate',
      PartnerID: 3,
      TotalPersonalPoints: 6310,
      TotalTeamPoints: 6310,
    },
    {
      ID: 9,
      UserID: 'robert_jones',
      FirstName: 'Robert',
      LastName: 'Jones',
      Rank: 'Costumer',
      PartnerID: 4,
      TotalPersonalPoints: 5823,
      TotalTeamPoints: 5823,
    },
    {
      ID: 10,
      UserID: 'michael_clark',
      FirstName: 'Michael',
      LastName: 'Clark',
      Rank: 'Costumer',
      PartnerID: 4,
      TotalPersonalPoints: 6101,
      TotalTeamPoints: 6101,
    },
    {
      ID: 11,
      UserID: 'william_harris',
      FirstName: 'William',
      LastName: 'Harris',
      Rank: 'Affiliate',
      PartnerID: 5,
      TotalPersonalPoints: 4267,
      TotalTeamPoints: 4267,
    },
    {
      ID: 12,
      UserID: 'elizabeth_thompson',
      FirstName: 'Elizabeth',
      LastName: 'Thompson',
      Rank: 'Affiliate',
      PartnerID: 5,
      TotalPersonalPoints: 4267,
      TotalTeamPoints: 4267,
    },
    {
      ID: 13,
      UserID: 'charles_martin',
      FirstName: 'Charles',
      LastName: 'Martin',
      Rank: 'Leader 1',
      PartnerID: 1,
      TotalPersonalPoints: 3127,
      TotalTeamPoints: 13317,
    },
    {
      ID: 14,
      UserID: 'susan_white',
      FirstName: 'Susan',
      LastName: 'White',
      Rank: 'Costumer',
      PartnerID: 6,
      TotalPersonalPoints: 6330,
      TotalTeamPoints: 6330,
    },
    {
      ID: 15,
      UserID: 'david_king',
      FirstName: 'David',
      LastName: 'King',
      Rank: 'Costumer',
      PartnerID: 6,
      TotalPersonalPoints: 5101,
      TotalTeamPoints: 5101,
    }
  ];