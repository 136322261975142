
export const colors = {
  rankColors: {
    Costumer: '#F3EBB2',
    Affiliate: '#F3EBB2',
    'Leader 1': '#ffb74d',
    'Leader 2': '#ffa726',
    'Leader 3': '#f57c00',
  },
  accordionColors: {
    color1: '#7D9A9A',
    color2: '#6c8484',
    text: '#333',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    hoverBg: '#e0e0e0',
    hoverBoxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
  },
  backgroundColors: {
    pageBackground: '#020024',
    contentBackground: '#82a3a3',
    personalChipBackground: '#E1C7E6',
    teamChipBackground: '#F5B3C3',
  },
};
//shoppingbag, businesscenter, starBorder,StarBorderPurple500,Star