
import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography, Chip, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import { distributorsData, Distributor } from './distributorData';
import { SecuredLayout } from '../Layout';
import FaceIcon from '@mui/icons-material/Face';
import GroupIcon from '@mui/icons-material/Group';
import { colors } from '../Layout/colors';  // Import the colors
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import StarIcon from '@mui/icons-material/Star';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';  // Import the Collapse Icon
interface DistributorAccordionProps {
  distributor: Distributor;
  expandedAll: boolean;
  colorTicker: boolean;
}
const getRankIcon = (rank: string) => {
  switch (rank) {
    case 'Costumer':
      return <ShoppingBagIcon sx={{ mr: 1, color: colors.rankColors[rank] || '#fff' }} />;
    case 'Affiliate':
      return <BusinessCenterIcon sx={{ mr: 1, color: colors.rankColors[rank] || '#fff' }} />;
    case 'Leader 1':
      return <StarBorderIcon sx={{ mr: 1, color: colors.rankColors[rank] || '#fff' }} />;
    case 'Leader 2':
      return <StarBorderPurple500Icon sx={{ mr: 1, color: colors.rankColors[rank] || '#fff' }} />;
    case 'Leader 3':
      return <StarIcon sx={{ mr: 1, color: colors.rankColors[rank] || '#fff' }} />;
    default:
      return null;
  }
};
function formatPoints(points: number, currency = '') {
  const formattedPoints = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  }).format(points);

  return formattedPoints;
}

const DistributorAccordion: React.FC<DistributorAccordionProps> = ({ distributor, expandedAll, colorTicker }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // Find the child distributors based on the current distributor's ID
  const childDistributors = distributorsData.filter((d) => d.PartnerID === distributor.ID);
  const icon = getRankIcon(distributor.Rank); // Store the icon in a variable
  return (
    <SecuredLayout>
      <Accordion
        expanded={expandedAll || expanded}
        onChange={childDistributors.length > 0 ? handleToggle : undefined}
        sx={{
          backgroundColor: colorTicker ? colors.accordionColors.color1 : colors.accordionColors.color2,
          color: colors.accordionColors.text,
          mt: 1,
          mb: 1,
          boxShadow: colors.accordionColors.boxShadow,
        }}
      >
        <AccordionSummary expandIcon={childDistributors.length > 0 ? <ExpandMoreIcon sx={{color: 'white' }} /> : <ExpandMoreIcon sx={{ visibility: 'hidden' }} />}>
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              {icon ? (
                <Tooltip title={distributor.Rank}>
                  {icon}
                </Tooltip>
              ) : null}
              <Typography variant="subtitle1" sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)', color: colors.rankColors[distributor.Rank] || '#fff', fontWeight: 'bold' }}>
                {distributor.FirstName} {distributor.LastName}
              </Typography>
            </Box>
            <Box>
              <Tooltip title="Total Personal Points">
                <Chip
                  label={formatPoints(distributor.TotalPersonalPoints)}
                  icon={<FaceIcon />}
                  sx={{
                    mr: 1,
                    backgroundColor: colors.backgroundColors.personalChipBackground,
                    color: colors.accordionColors.text,
                    boxShadow: colors.accordionColors.boxShadow,
                    padding: '8px 12px',
                    borderRadius: '16px',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: colors.accordionColors.hoverBg,
                      boxShadow: colors.accordionColors.hoverBoxShadow,
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="Total Team Points">
                <Chip
                  label={formatPoints(distributor.TotalTeamPoints)}
                  icon={<GroupIcon />}
                  sx={{
                    mr: 1,
                    backgroundColor: colors.backgroundColors.teamChipBackground,
                    color: colors.accordionColors.text,
                    boxShadow: colors.accordionColors.boxShadow,
                    padding: '8px 12px',
                    borderRadius: '16px',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: colors.accordionColors.hoverBg,
                      boxShadow: colors.accordionColors.hoverBoxShadow,
                    }
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {childDistributors.length > 0 && (
            <Box ml={0.2} mr={0.2} mb={1}>
              {childDistributors.map((childDistributor) => (
                <DistributorAccordion key={childDistributor.ID} distributor={childDistributor} expandedAll={expandedAll} colorTicker={!colorTicker} />
              ))}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </SecuredLayout>
  );
};

const MyTeamPage: React.FC = () => {
  const [expandedAll, setExpandedAll] = useState(false);

  const handleExpandAll = () => {
    setExpandedAll(!expandedAll);
  };

  // Find the top-level distributors who do not have a parent (PartnerID is null)
  const topLevelDistributors = distributorsData.filter((d) => d.PartnerID === null);

  return (
    <SecuredLayout>
      <div style={{ backgroundColor: colors.backgroundColors.pageBackground, minHeight: 'calc(100vh - 96px)', paddingTop: '30px' }}>
        <Box sx={{  width: '80%', margin: '0 auto', padding: 2, backgroundColor: colors.backgroundColors.contentBackground, borderRadius: 2, boxShadow: 3 }}>
          {/* Use a flex container to align the title and button horizontally */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', color: colors.accordionColors.text, fontWeight: 'bold', textShadow: colors.accordionColors.boxShadow }}>
              My Team
            </Typography>
            <IconButton onClick={handleExpandAll} sx={{ ml: 2, boxShadow: colors.accordionColors.boxShadow }}>
              <Tooltip title={expandedAll ? 'Collapse All' : 'Expand All'}>
                {expandedAll ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
              </Tooltip>
            </IconButton>
          </Box>
          {topLevelDistributors.map((distributor) => (
            <DistributorAccordion key={distributor.ID} distributor={distributor} expandedAll={expandedAll} colorTicker={false} />
          ))}
        </Box>
      </div>
    </SecuredLayout>
  );
};

export default MyTeamPage;
