import React, { useEffect, useState } from 'react';
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {Tooltip } from '@mui/material';
import {
    Box,
    CssBaseline,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Drawer
} from '@mui/material';
import { Greeter, PageHeader } from './styles';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useAuth } from '../../hooks/useAuth';
import { resolveIcon } from '../../utils/iconResolver';
import Footer from './Footer';
import MetamaskLogin from '../Login/MetamaskLogin';
import { colors } from './colors';

const drawerWidth = 220;
const OPENAIRT_API_URL = process.env.REACT_APP_OPENAIRT_API_URL || '';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    marginLeft: 0,
    minHeight: '60px!important',
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}


interface StyledIconButtonProps {
    open: boolean;
}

export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(({ theme, open }) => ({

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...(open && { display: 'none' }), // Ha az open true, elrejti az IconButton-t
}));


const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    background: colors.backgroundColors.pageBackground,
    color: "lightgray!important",
    fill: "lightgray!important"
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    background: colors.backgroundColors.pageBackground,
    color: "lightgray!important",
    fill: "lightgray!important",
    width: `calc(${theme.spacing(4)})`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)})`,
    },
});


export const CustomDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': {
                        ...openedMixin(theme),
                        //background: colors.backgroundColors.pageBackground,
                        color: "lightgray!important",
                        fill: "lightgray!important",
                    },
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': {
                        ...closedMixin(theme),
                        //background: colors.backgroundColors.pageBackground,
                        color: "lightgray!important",
                        fill: "lightgray!important",
                    },
                },
            },
        ],
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

interface NavBarItemType {
    Title: string;
    Url: string;
    Icon: string;
}

interface NavBarResponse {
    navItems: NavBarItemType[];
}

type Props = {
    children: JSX.Element | JSX.Element[];
};

const DefaultItems: NavBarItemType[] = [
    {
        Title: 'Home',
        Url: '/',
        Icon: 'Home',
    },
    {
        Title: 'Login',
        Url: '/login',
        Icon: 'Login',
    },
    {
        Title: 'Register',
        Url: '/register',
        Icon: 'HowToReg',
    },
];

const Layout: React.FC<Props> = ({ children }) => {
    const { user, logout } = useAuth();
    const [items, setItems] = useState<NavBarItemType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [hasNavBarResponse, setHasNavBarResponse] = useState(false);

    const fetchNavItems = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await fetch(OPENAIRT_API_URL + '/api/v1/navbar', {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`
                },
            });

            if (response.status === 401) {
                return;
            }
            const body: NavBarResponse = await response.json();
            body.navItems && setItems(body.navItems);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            setHasNavBarResponse(true);
        }
    };

    useEffect(() => {
        if (!user) return;
        fetchNavItems();
    }, [user]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh', // biztosítja, hogy a teljes képernyő magasságát kitöltse
            }}
        >
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{backgroundColor: colors.backgroundColors.pageBackground, borderBottom: '2px solid white'  }}>
                <Toolbar sx={{ px: 0, minHeight: '60px!important' }} disableGutters>
                    <StyledIconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        open={open}
                    >
                        <MenuIcon />
                    </StyledIconButton>
                    <PageHeader px={2}
                        sx={{
                            backgroundColor: colors.backgroundColors.pageBackground,
                            display: 'flex',
                            justifyContent: 'flex-end',  // Aligns content to the right
                            alignItems: 'center'         // Vertically centers the content
                        }}
                    >
                        {/*                         <Link href='/'>
                            <img src='/images/logo.svg' alt='OpenAIrt logo' />
                        </Link> */}
                        {user ? (
                            <Greeter sx={{ color: 'white', fontWeight: 'bold' }}>
                                Hi {user.name}!  
                                <Tooltip title= 'Log Out'>
                                <IconButton onClick={() => logout()}>
                                    <LogoutIcon sx={{ fill: 'white' }} />
                                </IconButton>
                                </Tooltip>
                            </Greeter>
                        ) : (
                            <Greeter sx={{ color: 'white', fontWeight: 'bold' }}>
                                <MetamaskLogin />
                                <Link href={"/login"}>
                                    Login
                                </Link>
                                <Link href={"/register"}>
                                    Register
                                </Link>
                            </Greeter>
                        )}
                    </PageHeader>
                </Toolbar>
            </AppBar>
            <CustomDrawer variant='permanent' open={open}>
                <DrawerHeader>
                    <IconButton onClick={() => setOpen(!open)}>
                        <ChevronLeftIcon sx={{ fill: 'lightgray!important' }} />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                {user ? (
                    <List >
                        {hasNavBarResponse && items.length > 0 ? (
                            items.map(item => {
                                const href = item.Url;
                                const Icon = resolveIcon(item.Icon);
                                return (
                                    <ListItem
                                        key={item.Url}
                                        disablePadding
                                        sx={{ fill: 'lightgray!important', display: 'block' }}
                                        title={item.Title}>
                                        <Link href={href} underline={'none'} color={'inherit'}>
                                            <ListItemButton
                                                selected={window.location.href.includes(item.Url)}
                                                sx={{
                                                    minHeight: 10,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 1.125,
                                                    py: 0.125,
                                                    color: 'lightgray!important',
                                                    '& .MuiListItemIcon-root': {
                                                        '& .Mui-selected': {
                                                            backgroundColor: 'red!important',
                                                        },
                                                    },
                                                }}>
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1 : 'auto',
                                                        justifyContent: 'center',
                                                        color: 'lightgray',
                                                    }}
                                                >
                                                    {Icon}
                                                </ListItemIcon>
                                                <ListItemText primary={item.Title} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </Link>
                                    </ListItem>
                                );
                            })
                        ) : null}
                    </List>
                ) : (
                    <List>
                        {DefaultItems.map(item => {
                            const href = item.Url;
                            const Icon = resolveIcon(item.Icon);
                            return (
                                <ListItem
                                    key={item.Url}
                                    disablePadding
                                    sx={{ display: 'block' }}
                                    title={item.Title}>
                                    <Link href={href} underline={'none'} color={'inherit'}>
                                        <ListItemButton
                                            selected={window.location.href.includes(item.Url)}
                                            sx={{
                                                minHeight: 10,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 1.125,
                                                py: 0.125,
                                                '& .MuiListItemIcon-root': {
                                                    '& .Mui-selected': {
                                                        backgroundColor: 'red!important',
                                                    },
                                                },
                                            }}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 1 : 'auto',
                                                    justifyContent: 'center',
                                                    color: 'lightgrey',
                                                }}
                                            >
                                                {Icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.Title} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </CustomDrawer>

            {/* A fő tartalom, amely kitölti a rendelkezésre álló helyet */}
            <Box
                component='main'
                sx={{
                    flexGrow: 1,
                    mt: '60px', // Maradék magasságot kitöltve tartalommal
                    display: 'flex',
                    flexDirection: 'column'
                    
                }}
            >
                {children}
            </Box>

            {/* Footer a lap alján */}
            <Footer />
        </Box>
    );
};

export { Layout };
