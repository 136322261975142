import React, { createContext, useContext, useEffect, useState } from 'react';
import { Props } from '../@types';
import { decodePayload } from '../utils/decodePayload';
import { AuthContextType, AuthResponseType, RegisterPayload, User } from '../@types/auth';
import { useProvideSnackBar } from './useSnackBar';

const OPENAIRT_API_ENDPOINT = process.env.REACT_APP_OPENAIRT_API_URL || '';

const AuthContext = createContext<AuthContextType>({
    user: undefined,
    isAuthenticated: false,
    login: (email: string, password: string) => console.log('signIn'),
    loginWeb3: (account: string, password: string) => console.log('signIn'),
    register: (registerPayload: RegisterPayload) => console.log('register'),
    logout: () => console.log('signOut'),
});

export const useAuth = () => useContext(AuthContext);

export const ProvideAuth = ({ children }: Props) => {
    const { showResponseError } = useProvideSnackBar();
    const [user, setUser] = useState<User>();
    const [accessToken, setAccessToken] = useState<string>(localStorage.getItem('auth-token') || '');
    const [refreshToken, setRefreshToken] = useState<string>(localStorage.getItem('refresh-token') || '');

    const logout = () => {
        setUser(undefined);
        localStorage.removeItem('auth-token');
        localStorage.removeItem('refresh-token');
        window.location.assign('/');
    };

    const login = async (email: string, password: string) => {
        try {
            const response = await fetch(OPENAIRT_API_ENDPOINT + '/api/v1/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                }),
            })
            if (response.ok) {
                const authResponse: AuthResponseType = await response.json();
                setAccessToken(authResponse.access_token);
                localStorage.setItem('auth-token', authResponse.access_token);
                setRefreshToken(authResponse.refresh_token);
                localStorage.setItem('refresh-token', authResponse.refresh_token);
                signIn(authResponse.access_token, authResponse.refresh_token);
                window.location.assign('/');
            }
        } catch (error: any) {
            showResponseError(error);
        }
    };

    const loginWeb3 = async (account: string, password: string) => {
        try {
            const response = await fetch(OPENAIRT_API_ENDPOINT + '/api/v1/loginweb3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: account,
                    password: password
                }),
            })
            if (response.ok) {
                const authResponse: AuthResponseType = await response.json();
                setAccessToken(authResponse.access_token);
                localStorage.setItem('auth-token', authResponse.access_token);
                setRefreshToken(authResponse.refresh_token);
                localStorage.setItem('refresh-token', authResponse.refresh_token);
                signIn(authResponse.access_token, authResponse.refresh_token);
                window.location.assign('/');
            }
        } catch (error: any) {
            showResponseError(error);
        }
    };

    const register = async (registerPayload: RegisterPayload) => {
        try {
            const response = await fetch(OPENAIRT_API_ENDPOINT + '/api/v1/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    firstName: registerPayload.firstName,
                    lastName: registerPayload.lastName,
                    email: registerPayload.email,
                    partnerId: registerPayload.partnerId,
                    password: registerPayload.password
                }),
            });
            if (response.ok) {
                const authResponse: AuthResponseType = await response.json();
                setAccessToken(authResponse.access_token);
                localStorage.setItem('auth-token', authResponse.access_token);
                setRefreshToken(authResponse.refresh_token);
                localStorage.setItem('refresh-token', authResponse.refresh_token);
                signIn(authResponse.access_token, authResponse.refresh_token);
                window.location.assign('/');
            }
        } catch (error: any) {
            showResponseError(error);
        }
    }

    const signIn = async (authToken: string, refreshToken: string) => {
        try {
            const { exp, preferred_username, family_name, given_name, email, realm_access } = decodePayload(authToken);
            const resolvedUser = {
                username: preferred_username,
                name: `${given_name} ${family_name}`,
                email,
                roles: realm_access.roles,
                accessToken: authToken,
                refreshToken: refreshToken,
                exp,
                hasRole: (role: string) => realm_access.roles.some(r => r === role),
            };
            setUser(resolvedUser);
        } catch (error: any) {
            showResponseError(error);
            logout();
        }
    }

    useEffect(() => {
        const initAuth = async () => {
            if (accessToken) {
                await signIn(accessToken, refreshToken);
            }
        };
        initAuth();
    }, []);

    return <AuthContext.Provider value={{
        user,
        isAuthenticated: !!user,
        login,
        loginWeb3,
        register,
        logout
    }}> {children}</AuthContext.Provider >;
};

