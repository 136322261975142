import React, { useState } from 'react';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSDK } from "@metamask/sdk-react";
import PasswordModal from './PasswordModal';
import { useAuth } from '../../hooks/useAuth';

export default function MetamaskLogin() {
    const { loginWeb3 } = useAuth();
    const [account, setAccount] = useState<string>();
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState<string>("");
    const { sdk, connected, connecting, provider, chainId } = useSDK();

    const connect = async () => {
        try {
            const accounts = await sdk?.connect() as string[];
            console.log(accounts);
            setAccount(accounts[0]);
            console.log(provider);
            console.log(chainId);
            console.log(connected);
            setOpen(true);
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = () => {
        setOpen(false);
    };

    const isMetamaskInstalled = () => {
        return typeof (window as any).ethereum !== 'undefined';
    }

    return (
        <Grid>
            <Button onClick={connect} sx={{ color: 'white', fontWeight: 'bold' }}>
                Connect with Metamask
            </Button>
            <PasswordModal
                open={open}
                onClose={() => setOpen(false)}
                password={password}
                setPassword={setPassword}
                onSubmit={() => {
                    setOpen(false);
                    if (!account || account?.trim() === "") {
                        return;
                    }
                    loginWeb3(account, password);
                }}
            />
        </Grid>
    );
}