import React, { useState } from 'react';
import { Avatar, Box, Button, TextField, Typography, CssBaseline } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAuth } from '../../hooks/useAuth';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();

    return (
        <Box component='main' sx={{ flexGrow: 1, mt: '60px' }}>
            <Grid>
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <CssBaseline />
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">Sign in</Typography>
                    <Box>
                        <TextField
                            margin="normal" required fullWidth
                            id="email" label="Email Address" name="email"
                            autoComplete="email" autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => login(email, password)}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Box>
    );
};
