import { Box, Card } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

export const PageHeader = styled(Box)`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: green;
    width: 100%;
`;

export const Greeter = styled(Box)`
    color: green;
    display: flex;
    align-items: center;
`;

export const SlowCircularProgress = styled(CircularProgress)({
    animationDuration: '2s', // Lassítja az animációt
});

export const StyledDetailsCard = styled(Card)`
    padding: 4px 8px;
    width: 100%;
    flex-direction: column;
    display: flex;
    box-sharod: 0 4px 6px rgba(0, 0, 0, 0.3);
`;

export const ConfirmCard = styled(Card)`
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 400px;
    background-color: white;
    box-shadow: 2px 2px;
    padding-bottom: 14px;

    @media (max-width: 900px) {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0%, 0%);
        with: 100%;
    }
`