import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, CircularProgress } from '@mui/material';
import { GalleryItem } from '../../@types/gallery';
import GalleryCard from './GalleryCard';
import { useProvideSnackBar } from '../../hooks/useSnackBar';
import { SecuredLayout } from '../Layout/SecuredLayout';
import { colors } from '../Layout/colors';

const OPENAIRT_APP_URL = process.env.REACT_APP_OPENAIRT_APP_URL || '';

export default function GalleryPage() {
    const { showError, showResponseError } = useProvideSnackBar();
    const [itemData, setItemData] = useState<GalleryItem[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(OPENAIRT_APP_URL + '/image_data.json', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setItemData(data);
                return;
            }
            showResponseError(response);
        } catch (e) {
            showError('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <SecuredLayout>
            {!loading ? (
                <Box
                    sx={{
                        background: colors.backgroundColors.pageBackground,
                        padding: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh'
                    }}
                >
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        columns={{ xs: 1, sm: 2, md: 3 }}
                    >
                        {itemData.map((item) => (
                            <Grid key={item.id} sx={{ margin: '10px' }}>
                                <GalleryCard galleryItem={item} />
                            </Grid>
                        ))}
                    </Grid>
                </Box >
            ) : (
                <CircularProgress />
            )}
        </SecuredLayout>
    );
}