import React from 'react';
import { Modal, CardContent, CardActions, Typography, TextField, Button } from '@mui/material';
import { ConfirmCard } from '../Layout/styles';

interface PasswordModalProps {
    open: boolean;
    onClose: () => void;
    password: string;
    setPassword: (e: string) => void;
    onSubmit: () => void;
}

export default function PasswordModal({
    open,
    password,
    setPassword,
    onClose,
    onSubmit
}: PasswordModalProps) {
    return (
        <Modal open={open} onClose={onClose}>
            <ConfirmCard>
                <CardContent>
                    <Typography paragraph variant="h6" ml={2}>Please enter your password</Typography>
                </CardContent>
                <CardActions>
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button onClick={onSubmit} variant="contained" color="primary" fullWidth>
                        Submit
                    </Button>
                </CardActions>
            </ConfirmCard>
        </Modal>
    );
}