import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { Box } from "@mui/material";
import { Props } from "../../@types";
import LoginPage from "../Login/LoginPage";

const SecuredLayout: React.FC<Props> = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
        return <LoginPage />
    }

    return (
        <Box>{children}</Box>
    )
};

export { SecuredLayout };